/* eslint-disable react-hooks/exhaustive-deps */
import { tokens } from "../../theme";
import { Box, Grid, Tooltip, Typography, useTheme } from "@mui/material";
import DataGridButton from "../../components/DataGridButton";
import {
  DB_PATCH_REPO_NAME,
  REQUEST_STATUS,
  REQUEST_VIEWS,
} from "../../utils/constants";
import { simpleCardStyle } from "../../styles/cardStyles";
import styles from "./styles";
import { dataGridButtonTypography } from "../../styles/DataGridStyles";
import { dataGridButtonStyles } from "../../styles/buttonStyles";
import { MsSqlServer, MySql, Oracle, PostgreSql } from "../../icons";
import { githubUrl } from "../../utils/urls";
import utilColors from "../../styles/utilColors";
import RedirectLink from "../../components/RedirectLink";
import { stackCenterGap } from "../../styles/utilStyles";
import { useCloseRequest } from "../../api/dbPatch";
import { useContext, useEffect } from "react";
import { UserContext } from "../../contexts";

const cloudIconProps = { size: "30px" };
const cloudIcons = {
  mysql: <MySql {...cloudIconProps} />,
  postgresql: <PostgreSql {...cloudIconProps} />,
  sqlservice: <MsSqlServer {...cloudIconProps} />,
  oracle: <Oracle {...cloudIconProps} />,
};
const CloudIcon = ({ cloud }) => {
  const icon = cloudIcons[cloud];
  return (
    icon || (
      <Box
        component="div"
        sx={{
          display: "inline",
          fontSize: "large",
        }}
      >
        {cloud}
      </Box>
    )
  );
};

const RequestCard = ({
  request,
  setOpenSubmitRequestDialog,
  displayNotification,
  callback,
  view,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const loggedInUser = useContext(UserContext);

  const {
    postData: closeRequest,
    response: closeRequestResp,
    responseStatus: closeRequestStatus,
  } = useCloseRequest(request?.payloadJson?.requestId);

  useEffect(() => {
    displayNotification(closeRequestResp, closeRequestStatus, callback);
  }, [closeRequestResp, closeRequestStatus]);

  return (
    <Box sx={simpleCardStyle(colors)}>
      <Box sx={styles.verticalStack}>
        <Grid container>
          <Grid item xs={3} sx={styles.requestJsonKey}>
            Engines:
          </Grid>
          <Grid item xs={9}>
            {[
              ...request?.payloadJson?.patchDetails?.reduce(
                (accum, curr) => accum.add(curr?.name.toLowerCase()),
                new Set()
              ),
            ].map((item, i) => (
              <Box
                key={i}
                component="div"
                sx={{
                  display: "inline",
                  ml: "10px",
                }}
              >
                <CloudIcon cloud={item} />
              </Box>
            ))}
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={3} sx={styles.requestJsonKey}>
            Submitted By:
          </Grid>
          <Grid item xs={9}>
            {request?.userEmail}
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={3} sx={styles.requestJsonKey}>
            JIRA:
          </Grid>
          <Grid item xs={9}>
            {request?.payloadJson?.buildConfig?.jiraId}
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={3} sx={styles.requestJsonKey}>
            Source CP:
          </Grid>
          <Grid item xs={9}>
            {request?.payloadJson?.tenantConfig?.mothersite}-
            {request?.payloadJson?.tenantConfig?.controlPlane}
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={3} sx={styles.requestJsonKey}>
            PR:
          </Grid>
          <Grid item xs={4}>
            {request?.status === "RUNNING" ? (
              "---"
            ) : (
              <RedirectLink
                href={`${githubUrl}/${DB_PATCH_REPO_NAME}/compare/${request?.payloadJson?.buildConfig?.tessellRelease}...${request?.payloadJson?.buildConfig?.jiraId}?expand=1`}
                color={utilColors.skyBlue}
              >
                <Typography variant="h5">Create/View</Typography>
              </RedirectLink>
            )}
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={3} sx={styles.requestJsonKey}>
            Test CP:
          </Grid>
          <Grid item xs={4}>
            {request?.payloadJson?.testConfig?.tessellControlPlane || "---"}
          </Grid>
        </Grid>

        <Box sx={styles.reverseStack}>
          <Tooltip
            title={
              <>
                {request?.workflowStepsMetadata?.steps?.map((entry, i) => {
                  return (
                    <Typography
                      key={i}
                      sx={(() =>
                        entry?.status === "FAILED"
                          ? { color: colors.red[500] }
                          : {})()}
                    >
                      -- {entry?.step}
                    </Typography>
                  );
                })}
              </>
            }
          >
            <Typography
              variant="h5"
              sx={styles.requestStatusTypo(colors, request?.status)}
            >
              {request?.status}
            </Typography>
          </Tooltip>
          <Box sx={stackCenterGap} />
          {view !== "CLOSED" ? (
            <>
              <DataGridButton
                sx={dataGridButtonStyles.requestApproveButton}
                hue={colors.green}
                onClick={() => setOpenSubmitRequestDialog(request)}
                disabled={loggedInUser?.email !== request?.userEmail}
              >
                <Typography sx={dataGridButtonTypography(colors)}>
                  Resubmit
                </Typography>
              </DataGridButton>
              <DataGridButton
                sx={dataGridButtonStyles.requestApproveButton}
                hue={colors.red}
                onClick={() => closeRequest()}
                disabled={loggedInUser?.email !== request?.userEmail}
              >
                <Typography sx={dataGridButtonTypography(colors)}>
                  Close
                </Typography>
              </DataGridButton>
            </>
          ) : (
            <></>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default RequestCard;
