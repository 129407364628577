/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useEffect, useState } from "react";
import {
  Box,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { TextFieldWithHelperText } from "../../components/TextFieldWithHelperText";
import { toCamelCase } from "../../utils/helperFunctions";
import { requestCPDialogStyles } from "./styles";
import ConvoyDialog from "../../components/ConvoyDialog";
import { ControlPointRounded, HighlightOffRounded } from "@mui/icons-material";
import { useSubmitDbPatchCreation } from "../../api/dbPatch";
import { useGetReleases } from "../../api/releases";

const SubmitRequestDialog = ({
  open,
  resetDialog,
  callback,
  displayNotification,
  payload,
  setPayload,
}) => {
  const {
    postData: submitDbPatchCreation,
    response: submitDbPatchCreationResp,
    responseStatus: submitDbPatchCreationStatus,
  } = useSubmitDbPatchCreation();

  const { postData: getReleases, response: getReleasesResp } =
    useGetReleases("tessell");

  useEffect(() => {
    open && getReleases("?loadRepos=false");
  }, [open]);

  const handleDialogConfirm = () => {
    submitDbPatchCreation(payload);
    callback();
    resetDialog();
  };

  const textFieldCommonProps = (label) => ({
    fullWidth: true,
    label: label,
    color: "secondary",
  });

  useEffect(() => {
    displayNotification(
      submitDbPatchCreationResp,
      submitDbPatchCreationStatus,
      callback
    );
  }, [submitDbPatchCreationResp, submitDbPatchCreationStatus]);

  return (
    <ConvoyDialog
      open={open}
      title="Submit DB Patch Request"
      handleDialogConfirm={handleDialogConfirm}
      resetDialog={resetDialog}
    >
      <Grid container spacing={2} sx={requestCPDialogStyles.gridContainer}>
        <Grid item xs={4}>
          <FormControl
            sx={{
              width: "100%",
            }}
          >
            <InputLabel size="string" color="secondary">
              Release Branch
            </InputLabel>
            <Select
              {...textFieldCommonProps("Release Label")}
              value={payload?.buildConfig?.tessellRelease}
              onChange={(e) => {
                setPayload((prev) => ({
                  ...prev,
                  buildConfig: {
                    ...prev?.buildConfig,
                    tessellRelease: e.target.value,
                  },
                }));
              }}
            >
              <MenuItem value={"main"}>main</MenuItem>
              {getReleasesResp?.response?.map((label, i) => (
                <MenuItem value={label?.release_label} key={i}>
                  {label?.release_label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <TextFieldWithHelperText
            {...textFieldCommonProps("Jira Id")}
            value={payload?.buildConfig?.jiraId}
            onChange={(e) => {
              setPayload((prev) => ({
                ...prev,
                buildConfig: {
                  ...prev?.buildConfig,
                  jiraId: e.target.value,
                },
              }));
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextFieldWithHelperText
            {...textFieldCommonProps("Test control plane")}
            value={payload?.testConfig?.tessellControlPlane}
            onChange={(e) => {
              setPayload((prev) => ({
                ...prev,
                testConfig: {
                  ...prev?.testConfig,
                  tessellControlPlane: e.target.value,
                },
              }));
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextFieldWithHelperText
            {...textFieldCommonProps("Mothersite")}
            value={payload?.tenantConfig?.mothersite}
            onChange={(e) => {
              setPayload((prev) => ({
                ...prev,
                tenantConfig: {
                  ...prev?.tenantConfig,
                  mothersite: e.target.value,
                },
              }));
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextFieldWithHelperText
            {...textFieldCommonProps("Control Plane")}
            value={payload?.tenantConfig?.controlPlane}
            onChange={(e) => {
              setPayload((prev) => ({
                ...prev,
                tenantConfig: {
                  ...prev?.tenantConfig,
                  controlPlane: e.target.value,
                },
              }));
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextFieldWithHelperText
            {...textFieldCommonProps("Tenant ID")}
            value={payload?.tenantConfig?.tenantId}
            onChange={(e) => {
              setPayload((prev) => ({
                ...prev,
                tenantConfig: {
                  ...prev?.tenantConfig,
                  tenantId: e.target.value,
                },
              }));
            }}
          />
        </Grid>
        <Grid item xs={11}>
          <Typography variant="h5">Patch details</Typography>
        </Grid>
        <Grid item xs={1}>
          <IconButton
            sx={{ m: 0 }}
            flex="1"
            mr={0}
            onClick={(event) =>
              setPayload((prev) => {
                return {
                  ...prev,
                  patchDetails: prev?.patchDetails
                    ? [...prev?.patchDetails, {}]
                    : [{}],
                };
              })
            }
          >
            <ControlPointRounded />
          </IconButton>
        </Grid>
      </Grid>
      {payload?.patchDetails?.map((patchDetail, i) => (
        <Fragment key={i}>
          <Divider />
          <Grid container spacing={1} sx={requestCPDialogStyles.gridContainer}>
            <Grid item xs={2.5}>
              <TextFieldWithHelperText
                {...textFieldCommonProps("Engine Name")}
                value={patchDetail?.name}
                onChange={(e) => {
                  setPayload((prev) => {
                    prev.patchDetails[i].name = e.target.value;
                    return { ...prev };
                  });
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <TextFieldWithHelperText
                {...textFieldCommonProps("Release Name")}
                value={patchDetail?.release}
                onChange={(e) => {
                  setPayload((prev) => {
                    prev.patchDetails[i].release = e.target.value;
                    return { ...prev };
                  });
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <TextFieldWithHelperText
                {...textFieldCommonProps("Patch Version")}
                value={patchDetail?.patchVersion}
                onChange={(e) => {
                  setPayload((prev) => {
                    prev.patchDetails[i].patchVersion = e.target.value;
                    return { ...prev };
                  });
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <TextFieldWithHelperText
                {...textFieldCommonProps("Version Name")}
                value={patchDetail?.versionName}
                onChange={(e) => {
                  setPayload((prev) => {
                    prev.patchDetails[i].versionName = e.target.value;
                    return { ...prev };
                  });
                }}
              />
            </Grid>
            <Grid item xs={0.5}>
              <IconButton
                sx={{ m: 0 }}
                flex="1"
                mr={0}
                onClick={(event) =>
                  setPayload((prev) => {
                    prev?.patchDetails?.splice(i, 1);
                    return { ...prev, patchDetails: prev?.patchDetails };
                  })
                }
              >
                <HighlightOffRounded />
              </IconButton>
            </Grid>
            <Grid item xs={6}>
              <TextFieldWithHelperText
                {...textFieldCommonProps("aws service id")}
                value={payload?.patchDetails?.[i]?.souceDbServices?.aws}
                onChange={(e) => {
                  setPayload((prev) => {
                    prev.patchDetails[i] = {
                      ...patchDetail,
                      souceDbServices: {
                        ...patchDetail?.souceDbServices,
                        aws: e.target.value,
                      },
                    };
                    return { ...prev };
                  });
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextFieldWithHelperText
                {...textFieldCommonProps("azure service id")}
                value={payload?.patchDetails?.[i]?.souceDbServices?.azure}
                onChange={(e) => {
                  setPayload((prev) => {
                    prev.patchDetails[i] = {
                      ...patchDetail,
                      souceDbServices: {
                        ...patchDetail?.souceDbServices,
                        azure: e.target.value,
                      },
                    };
                    return { ...prev };
                  });
                }}
              />
            </Grid>
          </Grid>
        </Fragment>
      ))}
    </ConvoyDialog>
  );
};

export default SubmitRequestDialog;

export const useSubmitRequestDialog = (
  callback = () => {},
  displayNotification = () => {}
) => {
  const [open, setOpen] = useState(false);
  const [payload, setPayload] = useState({});
  return {
    submitRequestDialog: (
      <SubmitRequestDialog
        open={open}
        resetDialog={() => setOpen(false)}
        callback={callback}
        displayNotification={displayNotification}
        payload={payload}
        setPayload={setPayload}
      />
    ),
    setOpenSubmitRequestDialog: (request) => {
      setOpen(true);
      setPayload(request?.payloadJson || {});
    },
  };
};
