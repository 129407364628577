import { commonDataGridStyles } from "../../styles/DataGridStyles";
import { colors as muiColors } from "@mui/material";

const styles = {
  mainBox: { mx: "5px" },
  gridContainer: {
    mt: "-10px",
    height: "10vh",
    alignItems: "center",
  },
  viewSelectForm: {
    minWidth: "170px",
    mt: "5px",
    ml: "2px",
    paddingX: "5px",
  },
  viewSelectSelect: {
    height: "35px",
  },
  filterText: {
    mt: "3px",
    ml: "-10px",
    p: "5px",
    width: "80px",
  },
  dataGridBoxStyles: (colors) => ({
    height: "86.7vh",
    ...commonDataGridStyles(colors),
  }),
  tabList: { borderBottom: 1, borderColor: "divider" },
  tabStyle: {
    mb: "-100px",
    pb: "60px",
    textTransform: "none",
  },
  requestCardsParentGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, 450px)",
    gap: 1,
  },
  // RequestCard
  reverseStack: {
    display: "flex",
    flexDirection: "row",
    mb: "0px",
    pb: "0px",
    mt: "auto",
  },
  verticalStack: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  requestJsonKey: { fontWeight: 700 },
  requestStatusTypo: (colors, status) => {
    const statusColor = {
      QA_FINISHED: colors.green[500],
      CLOSED: colors.red[500],
      FAILED: colors.red[500],
      TESTING_ERROR: colors.red[500],
    };
    return {
      mt: "15px",
      fontWeight: 600,
      color: statusColor?.[status] || muiColors.amber[300],
    };
  },

  // ---------
};
export const requestCPDialogStyles = {
  gridContainer: {
    padding: "7px",
    width: "50vw",
  },
  multiSelect: {
    height: "10px",
  },
};

export default styles;
