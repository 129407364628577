/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../contexts";
import { tokens } from "../../theme";
import {
  Box,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import Header from "../../components/Header";
import DataGridButton from "../../components/DataGridButton";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import ReleaseSummaryView from "./SummaryView";
import DetailView from "./DetailView";
import {
  useGetAppGroups,
  useGetReleases,
  useCreateLabel,
} from "../../api/releases";
import useIamModal from "../IamModal/UseIamModal";
import styles from "./styles";
import { toPascalCase } from "../../utils/helperFunctions";
import { USER_ROLES } from "../../utils/constants";
import { dataGridButtonTypography } from "../../styles/DataGridStyles";
import { dataGridButtonStyles } from "../../styles/buttonStyles";

const views = ["summary", "release", "service"];

const getReleasesQueryParams = (view) => {
  switch (view) {
    case "service":
      return "?serviceView=true";
    case "summary":
      return "?loadRepos=false";
    default:
      return "";
  }
};

const Releases = () => {
  const loggedInUser = useContext(UserContext);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [isUserReleaseOwner, setIsUserReleaseOwner] = useState(false);
  const [apps, setApps] = useState([]);
  const [selectedApp, setSelectedApp] = useState("");
  const [isLoadingView, setIsLoadingView] = useState(true);
  const [selectedView, setSelectedView] = useState("summary");

  const { IamModal, IamButton } = useIamModal(`/apps/${selectedApp}/releases`);

  const {
    postData: getApps,
    response: getAppsResp,
    isLoading: isLoadingApps,
  } = useGetAppGroups();

  const {
    postData: getReleases,
    response: getReleasesResp,
    isLoading: isLoadingReleases,
  } = useGetReleases(selectedApp);

  const { postData: createLabel, response: createLabelResp } =
    useCreateLabel(selectedApp);

  useEffect(() => {
    getApps();
  }, []);

  useEffect(() => {
    setIsLoadingView(isLoadingApps || isLoadingReleases);
  }, [isLoadingApps, isLoadingReleases]);

  useEffect(() => {
    setIsUserReleaseOwner(
      loggedInUser?.data?.roles?.includes(USER_ROLES.RELEASE_OWNER)
    );
  }, [loggedInUser?.data]);

  useEffect(() => {
    if (getAppsResp?.response?.length) {
      setApps(getAppsResp.response);
      setSelectedApp(getAppsResp.response[0]);
    }
  }, [getAppsResp]);

  useEffect(() => {
    if (selectedApp) {
      getReleases(getReleasesQueryParams(selectedView));
    }
  }, [createLabelResp, selectedApp]);

  const [confirmationDialog, setConfirmationDialog] = useState({
    isOpen: false,
    content: "",
    handleDialogConfirm: () => {},
  });

  const resetDialog = () => {
    setConfirmationDialog({
      isOpen: false,
      content: "",
      handleDialogConfirm: () => {},
    });
  };

  const handleCreateLabel = () => {
    setConfirmationDialog({
      isOpen: true,
      content: `You are going to create a new Label. Are you sure?`,
      handleDialogConfirm: () => {
        createLabel({});
      },
    });
  };

  const getView = () => {
    if (isLoadingView) {
      return (
        <Box sx={styles.loadingBox}>
          <CircularProgress color="secondary" />
        </Box>
      );
    }
    switch (selectedView) {
      case "summary":
        return (
          <ReleaseSummaryView
            selectedAppGroup={selectedApp}
            getReleases={getReleases}
            getReleasesResp={getReleasesResp}
          />
        );
      default:
        return (
          <DetailView getReleasesResp={getReleasesResp} view={selectedView} />
        );
    }
  };

  return (
    <Box m="5px">
      <ConfirmationDialog
        isOpen={confirmationDialog.isOpen}
        handleDialogConfirm={confirmationDialog.handleDialogConfirm}
        content={confirmationDialog.content}
        resetDialog={resetDialog}
      />
      <Grid mt={"20px"} container columns={2} alignItems={"center"}>
        <Grid item xs={1}>
          <Header
            title="Releases"
            subtitle={`Manage releases for: ${selectedApp}`}
          />
        </Grid>
        <Grid item xs={1}>
          <Stack direction={"row-reverse"}>
            <DataGridButton
              sx={dataGridButtonStyles.pageTopButtons}
              disabled={!isUserReleaseOwner}
              onClick={handleCreateLabel}
            >
              <Typography sx={dataGridButtonTypography(colors, 500)}>
                CREATE LABEL
              </Typography>
            </DataGridButton>
            <DataGridButton
              sx={dataGridButtonStyles.pageTopButtons}
              onClick={() => getReleases(getReleasesQueryParams(selectedView))}
            >
              <Typography sx={dataGridButtonTypography(colors, 500)}>
                RELOAD
              </Typography>
            </DataGridButton>
            {IamButton}
            <FormControl sx={styles.viewSelectForm}>
              <InputLabel id="select-label">View</InputLabel>
              <Select
                labelId="select-view-label"
                id="select-view"
                value={selectedView}
                sx={styles.viewSelectSelect}
                label="View"
                onChange={(e) => {
                  setIsLoadingView(true);
                  setSelectedView(e.target.value);
                  getReleases(getReleasesQueryParams(e.target.value));
                }}
              >
                {views.map((view, i) => (
                  <MenuItem value={view} key={i}>
                    {toPascalCase(view)} View
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={styles.viewSelectForm}>
              <InputLabel id="select-label">App</InputLabel>
              <Select
                labelId="select-app-label"
                id="select-app"
                value={selectedApp || " "}
                sx={styles.viewSelectSelect}
                label="App"
                onChange={(e) => setSelectedApp(e.target.value)}
              >
                {apps.map((app, i) => (
                  <MenuItem value={app} key={i}>
                    {app}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </Grid>
      </Grid>
      {IamModal}
      <Box>{getView()}</Box>
    </Box>
  );
};

export default Releases;
