import { useLazyFetch, useLazyPost } from "../utils/apiclient";
import { devopsUrl } from "../utils/urls";
import { getHeaders } from "../utils/apiclient";

export const useGetRequests = (status, all) => {
  return useLazyFetch(
    `${devopsUrl}/db-software/patches?status=${status ?? "ACTIVE"}&showAll=${
      all ?? false
    }`,
    { headers: getHeaders() }
  );
};

export const useCloseRequest = (id) => {
  return useLazyFetch(`${devopsUrl}/db-software/patches/${id}`, {
    headers: getHeaders(),
    method: "DELETE",
  });
};
export const useSubmitDbPatchCreation = () => {
  return useLazyPost(`${devopsUrl}/db-software/patches`, {
    headers: getHeaders(),
    method: "PUT",
  });
};
