/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { tokens } from "../../theme";
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Stack,
  Tab,
  Typography,
  useTheme,
} from "@mui/material";
import Header from "../../components/Header";
import DataGridButton from "../../components/DataGridButton";
import styles from "./styles";
import { dataGridButtonTypography } from "../../styles/DataGridStyles";
import { dataGridButtonStyles } from "../../styles/buttonStyles";
import { useGetRequests } from "../../api/dbPatch";
import RequestCard from "./RequestCard";
import { useSubmitRequestDialog } from "./SubmitRequestDialog";
import { useNotification } from "../../components/Notification";

const DbPatchTesting = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [selectedView, setSelectedView] = useState("ACTIVE");
  const [requests, setRequests] = useState([]);
  const [showAll, setShowAll] = useState(true);
  const { postData: getRequests, response: getRequestsResp } = useGetRequests(
    selectedView,
    showAll
  );

  const { notificationComponent, displayNotification } = useNotification();

  useEffect(() => {
    getRequests();
  }, [selectedView, showAll]);

  useEffect(() => {
    if (getRequestsResp?.requests) {
      setRequests(getRequestsResp?.requests);
    }
  }, [getRequestsResp]);

  const { submitRequestDialog, setOpenSubmitRequestDialog } =
    useSubmitRequestDialog(getRequests, displayNotification);

  return (
    <Box sx={styles.mainBox}>
      {notificationComponent}
      {submitRequestDialog}
      <Grid sx={styles.gridContainer} container columns={2}>
        <Grid item xs={1}>
          <Header
            title="Db Patch Requests"
            subtitle="Manage the db patch creation requests"
          />
        </Grid>
        <Grid item xs={1}>
          <Stack direction="row-reverse">
            <DataGridButton
              sx={dataGridButtonStyles.pageTopButtons}
              onClick={() => {
                setOpenSubmitRequestDialog();
              }}
            >
              <Typography sx={dataGridButtonTypography(colors, 500)}>
                SUBMIT NEW
              </Typography>
            </DataGridButton>
            <DataGridButton
              sx={dataGridButtonStyles.pageTopButtons}
              onClick={() => {
                getRequests();
              }}
            >
              <Typography sx={dataGridButtonTypography(colors, 500)}>
                RELOAD
              </Typography>
            </DataGridButton>
            <Box sx={styles.filterText}>Show all</Box>
            <Switch
              color="secondary"
              checked={showAll}
              onChange={() => setShowAll((prev) => !prev)}
            ></Switch>

            <FormControl sx={styles.viewSelectForm}>
              <InputLabel id="select-label">Status</InputLabel>
              <Select
                labelId="select-view-label"
                id="select-view"
                value={selectedView}
                sx={styles.viewSelectSelect}
                label="View"
                onChange={(e) => setSelectedView(e.target.value)}
              >
                {["ACTIVE", "CLOSED"].map((status, i) => (
                  <MenuItem value={status} key={i}>
                    {status}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </Grid>
      </Grid>
      <Box sx={styles.requestCardsParentGrid}>
        {requests
          ?.map((request, i) => (
            <RequestCard
              key={i}
              request={request}
              setOpenSubmitRequestDialog={setOpenSubmitRequestDialog}
              displayNotification={displayNotification}
              callback={getRequests}
              view={selectedView}
            />
          ))
          .filter(Boolean)}
      </Box>
    </Box>
  );
};

export default DbPatchTesting;
